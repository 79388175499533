export enum TagTypeKeys {
  'agency_job' = 'agency_job',
  'artist_kind' = 'artist_kind',
  'country' = 'country',
  'format' = 'format',
  'influencer_advanced' = 'influencer_advanced',
  'influencer_badge' = 'influencer_badge',
  'influencer_kind' = 'influencer_kind',
  'influencer_mark' = 'influencer_mark',
  'interaction' = 'interaction',
  'lyrics_lang' = 'lyrics_lang',
  'mood' = 'mood',
  'subgenre' = 'subgenre',
  'track_age' = 'track_age',
}
